<template>
  <div class="wallet">
    <div class="display">
      <div class="bg">
        <img src="@/assets/images/icon_file_search.png">
        <span class="times"><strong>{{ remain || 0 }}</strong>次</span>
        <span class="tips">剩余查询次数</span>
      </div>
      <el-button class="recharge-btn" type="primary" @click="handlePay">充值</el-button>
    </div>
    <base-box
      :topBarIcon="recordIcon"
      topBarLabel="充值记录"
      box-width="95%"
      box-height="608px">
      <div slot="content" class="content">
        <div class="table" style="width: 100%;">
          <el-table
            :data="recordTable"
            stripe
          >
            <el-table-column type="index" label="序号" width="160px;" align="center" />
            <el-table-column prop="payTime" label="充值时间" width="290px;" align="center" />
            <el-table-column prop="type" label="充值方式" align="center">
              <template slot-scope="scope">
                {{ scope.row.type | payType }}
              </template>
            </el-table-column>
            <el-table-column prop="result" label="充值结果" align="center">
              <template slot-scope="scope">
                {{ scope.row.result === 0 ? '成功' : '失败' }}
              </template>
            </el-table-column>
            <el-table-column prop="limit" label="充值次数" width="290px;" align="center" />
          </el-table>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pagination.pageSize"
          layout="prev, pager, next, total, sizes, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </base-box>
    <base-dialog
      :baseDialogVisible="payDialogVisible"
      :baseDialogShowClose="true"
      @closeDialog="closePayDialog"
    >
        <span slot="dialog-title">充值说明</span>
        <div>
          <div>
            <span class="label">支付方式：</span>
            <div class="pay-type">
              <div
                :class="`pay-item ${payWay === 'ali' && 'pay-item-active'}`"
                @click="switchPayWay('ali')"
              >
                <img src="@/assets/images/pay_ali@3x.png" alt="支付宝">
                <span>支付宝</span>
              </div>
              <div
                :class="`pay-item ${payWay === 'wechat' && 'pay-item-active'}`"
                @click="switchPayWay('wechat')"
              >
                <img src="@/assets/images/icon_wechat@3x.png" alt="微信">
                <span>微信</span>
              </div>
              <div
                :class="`pay-item ${payWay === 'card' && 'pay-item-active'}`"
                @click="switchPayWay('card')"
              >
                <img src="@/assets/images/icon_card@3x.png" alt="充值卡">
                <span>充值卡</span>
              </div>
            </div>
          </div>
          <div v-if="payWay && payWay !== 'card'">
            <span class="label">价格说明:</span>
            <p class="price-tip">请选择充值次数</p>
            <el-table
              id="tableCharge"
              ref="tableCharge"
              :data="priceTable"
              :show-header="false"
              size="mini"
              @select="selectPrice"
            >
              <!--            @select="selectPrice"-->
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column prop="times" align="center" />
              <el-table-column prop="price" align="center" />
            </el-table>
            <p v-if="selectedPrice" class="price-tip">
              当前选择充值方案：{{ selectedPrice.times }}， {{ selectedPrice.price }}
            </p>
          </div>
          <div v-if="payWay !== ''">
            <div v-if="payWay === 'card'" class="card-password">
              <p>充值卡密码</p>
              <el-input v-model="carPassword"></el-input>
            </div>
            <p v-if="payWay === 'card'" class="price-tip">绑定充值卡，绑定后有三个刷新点，可多次绑定</p>
            <div v-if="selectedPrice" class="qrcode">
              <!--             <div>二维码</div>-->
              <el-button type="primary"  @click="sureRecharge">确认充值</el-button>
            </div>
          </div>
        </div>
        <span v-if="payWay === 'card'" class="dialog-footer" slot="dialog-footer">
        <el-button @click="payDialogVisible=false">取消</el-button>
        <el-button type="primary" @click="cardRecharge">充值</el-button>
      </span>
    </base-dialog>
    <base-dialog  :baseDialogVisible="payWechatVisible"
                  :baseDialogShowClose="true"
                  @closeDialog="closeWechatDialog">
      <span slot="dialog-title">微信支付</span>
      <div class="wechat-header">
        <span v-if="count>0">距离二维码过期还剩<span class="count-text">{{count}}</span>
          秒，过期后请刷新重新获取二维码。</span>
        <span v-else>二维码已过期，
          <span class="count-text" style="cursor: pointer" @click="refreshCode">刷新</span>
          重新获取二维码。</span>
      </div>
      <div style="position: relative">
        <vue-qr  :text="wxPayObj.code_url" :size="350" style="display: flex;margin: 0 auto;">
        </vue-qr>
        <div v-if="count===0" class="code-mask" @click="refreshCode">
          <i class="el-icon-refresh-right"></i>
          <el-button class="refresh-code" type="text">二维码失效，点击刷新</el-button>
        </div>
      </div>
      <div class="wechat-foot">
        <img src="../../assets/images/scan.png">
        <span >请使用微信扫一扫扫描二维码支付</span>
      </div>
    </base-dialog>
  </div>
</template>

<script>
import {
  priceList, aliPay, wechatPay, wechatPayQuery,
} from '@/api/pay';
import { getUserId } from '@/utils/auth';
import * as ApiWallet from '@/api/account';
import { getUserRemainingNumber } from '@/api/user';
import BaseBox from '@/components/BaseBox/index.vue';
import BaseDialog from '@/components/BaseDialog/index.vue';
import vueQr from 'vue-qr';

const globalRecordIcon = require('@/assets/images/icon_record@3x.png');

export default {
  name: 'Wallet',
  components: {
    BaseBox,
    BaseDialog,
    vueQr,
  },
  filters: {
    payType(type) {
      let value = '';
      switch (type) {
        case 1:
          value = '支付宝';
          break;
        case 2:
          value = '微信';
          break;
        case 3:
          value = '充值卡';
          break;
        default:
          value = '';
          break;
      }
      return value;
    },
  },
  data() {
    return {
      carPassword: '',
      payWay: '',
      payDialogVisible: false,
      payWechatVisible: false,
      recordIcon: globalRecordIcon,
      remain: '',
      recordTable: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 400,
      },
      priceTable: [],
      selectedPrice: null,
      wxPayObj: {
        code_url: '',
      },
      num: 0, // 用来循环调后台计数
      in: null, // 记录interval方法的id
      count: 120,
      countInterval: null,
      qrcode: null,
    };
  },
  beforeMount() {
    if (this.$route.query.flag) {
      this.handlePay();
    }
  },
  mounted() {
    this.getTimes();
    this.getRecordTable();
  },
  methods: {
    cardRecharge() {
      if (!this.carPassword) {
        this.$message.messageError('请输入充值卡密码');
        return;
      }
      const params = {
        password: this.carPassword,
        payType: 3,
        userId: getUserId(),
      };
      ApiWallet.recharge(params).then((res) => {
        if (res.code === 0) {
          this.$message.messageSuccess('充值成功');
          this.payDialogVisible = false;
          this.getTimes();
          this.getRecordTable();
        } else {
          this.$message.messageError(res.message);
        }
      }).catch((error) => {
        this.$message.messageError(error.data.error);
      });
    },
    selectPrice(row, item) {
      this.$refs.tableCharge.clearSelection();
      this.$refs.tableCharge.toggleRowSelection(item);
      this.selectedPrice = item;
    },
    sureRecharge() {
      if (this.payWay === 'ali') {
        const params = {
          payType: 1,
          total: this.selectedPrice.moeny,
          userId: getUserId(),
        };
        aliPay(params).then((res) => {
          const div = document.createElement('payForm');
          div.innerHTML = res;
          div.hidden = true;
          document.body.appendChild(div);
          document.forms[document.forms.length - 1].submit();
        });
      } else if (this.payWay === 'wechat') {
        this.refreshCode();
        this.payDialogVisible = false;
        this.payWechatVisible = true;
      }
    },
    refreshCode() {
      if (document.getElementById('qrcodeUrl') && document.getElementById('qrcodeUrl').firstChild) {
        document.getElementById('qrcodeUrl').innerHTML = '';
      }
      const params = {
        payType: 2, // 支付类型 1：支付宝 2：微信
        total: this.selectedPrice.moeny,
        userId: getUserId(),
      };
      wechatPay(params).then((res) => {
        this.wxPayObj = res.data;
        this.count = 120;
        this.creatQrCode();
      });
    },
    creatQrCode() {
      this.num = 0;
      this.in = setInterval(() => {
        this.queryWechatPay();
      }, 3000);
      this.countInterval = setInterval(() => {
        if (this.count > 0) {
          this.count -= 1;
        } else {
          clearInterval(this.countInterval);
        }
      }, 1000);
    },
    queryWechatPay() {
      wechatPayQuery({ out_trade_no: this.wxPayObj.tradeNo }).then((res) => {
        if (res.code === 0) {
          clearInterval(this.in);
          this.$message.messageSuccess('充值成功！');
          this.payWechatVisible = false;
          this.getTimes();
          this.getRecordTable();
          if (document.getElementById('qrcodeUrl') && document.getElementById('qrcodeUrl').firstChild) {
            document.getElementById('qrcodeUrl').innerHTML = '';
          }
        }
      });
      this.num += 1;
      if (this.num >= 40) {
        clearInterval(this.in);
      }
    },
    switchPayWay(type) {
      this.selectedPrice = null;
      this.payWay = type;
      if (this.payWay !== 'card' && document.getElementById('tableCharge')) {
        this.$refs.tableCharge.clearSelection();
        const w = document.getElementById('tableCharge').clientWidth;
        document.getElementById('tableCharge').children.item(1).firstElementChild.style.width = w + 'px';
      }
    },
    handlePay() {
      this.payDialogVisible = true;
      this.carPassword = '';
      this.payWay = '';
      this.selectedPrice = null;
      this.getPriceList();
    },
    getPriceList() {
      priceList().then((res) => {
        if (res.code === 0) {
          this.priceTable = res.data.map((item) => {
            const result = {
              price: item.money + '元',
              times: item.useLimit + '次',
              moeny: item.money,
              userLimit: item.useLimit,
            };
            return result;
          });
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    closePayDialog() {
      this.payDialogVisible = false;
    },
    closeWechatDialog() {
      this.payWechatVisible = false;
      clearInterval(this.in);
      clearInterval(this.countInterval);
    },
    getRecordTable() {
      const params = {
        userId: getUserId(),
        ...this.pagination,
      };
      delete params.total;
      ApiWallet.getRechargeRecord(params).then((res) => {
        if (res.code === 0) {
          this.recordTable = res.data.list;
          this.pagination.total = Number(res.data.total);
          this.pagination.pageNo = res.data.pageNum;
          this.pagination.pageSize = res.data.pageSize;
        }
      });
    },
    getTimes() {
      const params = {
        userId: getUserId(),
      };
      getUserRemainingNumber(params).then((res) => {
        if (res.code === 0) {
          this.remain = res.data ? res.data.usableLimit : 0;
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getRecordTable();
    },
    handleCurrentChange(val) {
      this.pagination.pageNo = val;
      this.getRecordTable();
    },
  },
};
</script>

<style lang="scss" scoped>
.display {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 95%;
  max-width: 1400px;
  height: 184px;
  padding: 0 40px;
  margin: 20px auto;
  background: #FFFFFF;
  box-shadow: 0 4px 20px 0 rgba(221,221,221,0.60);
  border-radius: 4px;

  .bg {
    position: relative;
    width: 248px;
    height: 104px;
    font-size: 14px;
    color: #FFFFFF;
    background-image: linear-gradient(45deg, #C86DD7 0%, #3023AE 100%);
    box-shadow: 0 20px 20px -16px rgba(48,35,174,0.80);
    border-radius: 8px;

    img {
      position: absolute;
      top: 28px;
      left: 28px;
      width: 52px;
      height: 48px;
    }

    .times {
      position: absolute;
      top: 20px;
      left: 104px;

      strong {
        margin-right: 5px;
        font-size: 40px;
      }
    }

    .tips {
      position: absolute;
      top: 70px;
      left: 104px;
    }
  }

  .recharge-btn {
    position: absolute;
    top: 96px;
    right: 40px;
    width: 128px;
    height: 48px;
  }
}

/deep/ .el-dialog__body {
  text-align: left;
}
.label {
  display: inline-block;
  margin: 10px 0;
  font-weight: bold;
}
.pay-type {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .pay-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 100px;
    height: 40px;
    padding: 0 20px;
    font-size: 12px;
    background-color: #F8F8F8;
    border-radius: 4px;

    img {
      width: 23px;
      height: 23px;
      margin-right: 6px;
    }
  }

  .pay-item-active {
    border: 1px solid #0049EF;
    background-image: url('../../assets/images/icon_leftRight@3x.png');
    background-size: 22px;
    background-repeat: no-repeat;
  }
}

.card-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  height: 90px;
  font-size: 14px;
  font-weight: bold;
  background-color: #F8F8F8;

  p {
    width: 200px;
    margin-bottom: 5px;
  }
  .el-input {
    width: 200px;
    height: 40px;
  }
}

.price-tip {
  margin:5px 0;
  font-size: 13px;
  color: #1989fa;
}

.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  /*background-color: #F8F8F8;*/
}

.dialog-footer {
  .el-button {
    width: 120px;
  }
}

.qrcodeUrl{
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 330px;
    height: 330px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}
.wechat-header{
  width: 300px;
  font-size: 8px;
  margin: 0 auto;
  padding-bottom: 24px;
}
  .wechat-foot{
    background-color: #46a0fc;
    width: 300px;
    display: flex;
    margin: 0 auto;
    margin-top: 24px;
    color: #fff;
    img{
      width: 48px;
      height: 48px;
    }
    span{
      line-height: 28px;
      color: #fff;
      padding-left: 12px;
      display: inline-block;
      margin: 10px 0;
      font-weight: bold;
    }
  }
  .count-text{
    color: #46a0fc;
    font-weight: 600;
  }
  .code-mask{
    width: 330px;
    height: 330px;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.6);
    margin: -340px 30px;
    cursor: pointer;
    i{
      font-size: 50px;
      color: #fff;
      line-height: 270px;
      padding-left: 40%;
    }
    .refresh-code{
      color: #fff;
      margin-top: -100px;
      font-size: 24px;
    }
  }
</style>
