import request from '@/utils/request';

export function priceList() {
  return request({
    url: '/price/getList',
    method: 'get',
  });
}

// 支付宝支付
export function aliPay(data) {
  return request({
    url: '/pay/page',
    method: 'post',
    data,
  });
}

// 微信支付
export function wechatPay(data) {
  return request({
    url: '/pay/wxPay',
    method: 'post',
    data,
  });
}

// 微信支付结果查询
export function wechatPayQuery(params) {
  return request({
    url: '/pay/queryWx',
    method: 'post',
    params,
  });
}
