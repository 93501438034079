import request from '@/utils/request';

// 获取充值记录列表
export function getRechargeRecord(params) {
  return request({
    url: '/pay/record/getList',
    method: 'get',
    params,
  });
}

// 保存存值记录
export function saveRecharge(params) {
  return request({
    url: '/pay/record/add',
    method: 'get',
    params,
  });
}

export function recharge(data) {
  return request({
    url: '/user/limit/addLimit',
    method: 'post',
    data,
  });
}

export function minusLimit(data) {
  return request({
    url: '/user/limit/minusLimit',
    method: 'post',
    data,
  });
}
